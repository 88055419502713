:root {
    /* COLORS */
    /* Main Palette */
    --primary-color: #000080;
    --secondary-color: #DC0529;

    --error-color: #920000;
    --success-color: #006729;
    /* Grayscale */
    --ledger-color: var(--primary-color);
    --bank-color: #4A4A4A;
    --bank-color-hovered: #393939;
    --black-color: #000;
    --white-color: #fff;
    /*
    --dark-gray-color: #1a1c25;
    --gray-color: #2d303e;
    --light-gray-color: #c8c8c8;
     */

     /* Font Family */
     --font-family: 'Futura';
  
    /* Font Sizes */
    /* ->Desktop */
    /* --font-size-desktop-xs: 14px;
    --font-size-desktop-sm: 18px;
    --font-size-desktop-md: 32px;
    --font-size-desktop-lg: 40px;
    --font-size-desktop-xl: 48px; */
  
    /* Page */
    --page-max-width: 1152px;

    /* Tab */
    --tab-content-max-width: var(--page-max-width);
    --tab-content-min-width: 600px;

    /* Transaction */
    /* 1.Transaction Block: */
    --transaction-max-width: 560px;
    --transaction-header-font-size: 18px;
    --transaction-content-font-size: 12px;
    /* 2.Transaction Categories: */
    --date-width-sm: 75px;
    --description-width-sm: 220px;
    --reference-width-sm: 90px;
    /* amount: credit or debit */
    --amount-width-sm: 70px; 

    /* Spacing */
  
    /* Border Radius */
    --border-radius: 4px;
  }
  
  html,
  body {
    padding: 0;
    margin: 0;
    scroll-behavior: smooth;
  }
  
  a {
    color: inherit;
    text-decoration: none;
  }
  
  * {
    box-sizing: border-box;
  }
  
table, tr, td, th {
    border: 0px solid transparent !important;
    border-bottom: 1px solid rgba(224, 224, 224, 1) !important;
}

@tailwind base;
@tailwind components;
@tailwind utilities;